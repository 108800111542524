@import '../../../or_base/scss/theme-bootstrap';

// new for 2018 Creative Refresh
$cta-tout-prefix: cta-tout;
.#{$cta-tout-prefix} {
  font-family: $font--futura-book;
  margin: 12px 0;
  overflow: hidden;
  @include breakpoint($medium-up) {
    margin: 25px 0;
  }
  &.cta-tout-text {
    &--black {
      color: $color-cr18-black;
    }
    &--white {
      color: $color-white;
    }
    &--gray {
      color: $color-mid-gray;
    }
    &--green {
      color: $color-green;
    }
    &--lightgreen {
      color: $color-light-green;
    }
    &--ivory {
      color: $color-ivory;
    }
  }
  .product-grid__item & {
    @include breakpoint($medium-up) {
      height: inherit;
      margin: 0;
    }
  }
  .product-full__promotion & {
    $promo-pc-size: 125px;
    $promo-mobile-size: 105px;
    margin: 0;
    &__inner {
      max-height: $promo-mobile-size;
      min-height: auto;
      @include breakpoint($medium-up) {
        max-height: $promo-pc-size;
      }
    }
    &__text {
      padding: 10px;
      @include breakpoint($medium-up) {
        padding: 18px 10px;
      }
      &--product-image {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: $promo-mobile-size;
        width: auto;
        padding: 10px;
        @include breakpoint($medium-up) {
          height: $promo-pc-size;
        }
        .#{$cta-tout-prefix}__title,
        .#{$cta-tout-prefix}__copy-container {
          text-align: left;
          width: 100%;
          font-size: inherit;
        }
      }
    }
    &__scale-height-to-text {
      padding: 0;
      margin: 0;
    }
    &__title {
      @include breakpoint($medium-up) {
        font-size: 24px;
      }
    }
    &__button--btn-primary {
      margin-top: 10px;
      @include breakpoint($medium-up) {
        margin-top: 15px;
      }
      &:hover {
        background-color: $color-green;
        text-decoration: none;
      }
    }
    &__copy {
      font-size: 15px;
      &-container {
        @include breakpoint($medium-up) {
          padding-bottom: 0;
        }
      }
    }
    &__product--thumb {
      width: $promo-mobile-size;
      float: left;
      @include breakpoint($medium-up) {
        width: $promo-pc-size;
      }
      &-image {
        display: block;
      }
    }
    &--border {
      border-width: 1px;
      overflow: hidden;
    }
    &__lazyload-image {
      @include breakpoint($medium-up) {
        min-height: $promo-pc-size;
      }
    }
  }
  p {
    margin: 0;
    line-height: 1;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  &__scale-height-to-text {
    padding: 0 20px;
  }
  &__eyebrow {
    font-family: $font--futura-demi;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
    font-size: 16px;
    line-height: normal;
    @include breakpoint($medium-up) {
      font-size: 18px;
      letter-spacing: 1px;
    }
    p {
      font-size: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      font-family: inherit;
    }
    &.text-style--section-title-lowercase,
    &.text-style--tout-title-lowercase,
    &.text-style--lower-large-title-thin,
    &.text-style--lower-large-title {
      text-transform: none;
    }
  }
  &__mob-inline-eyebrow {
    &--20 {
      width: 20%;
    }
    &--30 {
      width: 30%;
    }
    &--40 {
      width: 40%;
    }
    &--50 {
      width: 50%;
    }
    &--60 {
      width: 60%;
    }
    &--70 {
      width: 70%;
    }
    &--80 {
      width: 80%;
    }
    &--90 {
      width: 90%;
    }
    &--100 {
      width: 100%;
    }
  }
  &__inline-eyebrow {
    margin: 0 auto;
    @include breakpoint($medium-up) {
      &--20 {
        width: 20%;
      }
      &--30 {
        width: 30%;
      }
      &--40 {
        width: 40%;
      }
      &--50 {
        width: 50%;
      }
      &--60 {
        width: 60%;
      }
      &--70 {
        width: 70%;
      }
      &--80 {
        width: 80%;
      }
      &--90 {
        width: 90%;
      }
      &--100 {
        width: 100%;
      }
    }
  }
  &__image-wrap {
    display: none;
    &--show-small-image {
      @include breakpoint($small-down) {
        display: block;
      }
    }
    &--show-large-image {
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    &--has-image-max-width {
      @include breakpoint($medium-up) {
        margin: 0 auto;
        display: block;
      }
      img {
        @include breakpoint($medium-up) {
          width: auto;
          height: auto;
          min-height: auto;
        }
      }
    }
    &--width-50 {
      @include breakpoint($medium-up) {
        width: 50%;
      }
    }
    &--width-55 {
      @include breakpoint($medium-up) {
        width: 55%;
      }
    }
    &--width-60 {
      @include breakpoint($medium-up) {
        width: 60%;
      }
    }
    &--width-65 {
      @include breakpoint($medium-up) {
        width: 65%;
      }
    }
    &--width-70 {
      @include breakpoint($medium-up) {
        width: 70%;
      }
    }
    &--width-75 {
      @include breakpoint($medium-up) {
        width: 75%;
      }
    }
    &--width-80 {
      @include breakpoint($medium-up) {
        width: 80%;
      }
    }
    &--width-85 {
      @include breakpoint($medium-up) {
        width: 85%;
      }
    }
    &--width-90 {
      @include breakpoint($medium-up) {
        width: 90%;
      }
    }
    &--width-95 {
      @include breakpoint($medium-up) {
        width: 95%;
      }
    }
  }
  &__title-mob {
    &--20 {
      width: 20%;
    }
    &--30 {
      width: 30%;
    }
    &--40 {
      width: 40%;
    }
    &--50 {
      width: 50%;
    }
    &--60 {
      width: 60%;
    }
    &--70 {
      width: 70%;
    }
    &--80 {
      width: 80%;
    }
    &--90 {
      width: 90%;
    }
    &--100 {
      width: 100%;
    }
  }
  &__title {
    clear: both;
    letter-spacing: normal;
    font-size: 18px;
    line-height: normal;
    font-family: $font--futura-demi;
    text-transform: uppercase;
    margin: 0 auto;
    font-weight: inherit;
    @include breakpoint($medium-up) {
      font-size: 32px;
      &:hover {
        text-decoration: none;
      }
      &--20 {
        width: 20%;
      }
      &--30 {
        width: 30%;
      }
      &--40 {
        width: 40%;
      }
      &--50 {
        width: 50%;
      }
      &--60 {
        width: 60%;
      }
      &--70 {
        width: 70%;
      }
      &--80 {
        width: 80%;
      }
      &--90 {
        width: 90%;
      }
      &--100 {
        width: 100%;
      }
    }
    p {
      letter-spacing: normal;
      font-size: inherit;
      line-height: inherit;
      font-family: inherit;
    }
    &.text-style--section-title-lowercase,
    &.text-style--tout-title-lowercase,
    &.text-style--lower-large-title-thin,
    &.text-style--lower-large-title {
      text-transform: none;
    }
  }
  &__title,
  &__button {
    &--font-book,
    &--font-book p {
      font-family: $font--futura-book;
    }
    &--font-demi,
    &--font-demi p {
      font-family: $font--futura-demi;
    }
    &--section-title,
    &--section-title p {
      font-size: 18px;
      @include breakpoint($portrait-up) {
        font-size: 32px;
      }
    }
    &--large-title,
    &--large-title p {
      font-size: 32px;
      @include breakpoint($portrait-up) {
        font-size: 60px;
      }
    }
  }
  &__copy-container {
    clear: both;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    font-family: $font--futura-book;
    @include breakpoint($medium-up) {
      font-size: 24px;
      padding-bottom: 20px;
      &--20 {
        width: 20%;
      }
      &--30 {
        width: 30%;
      }
      &--40 {
        width: 40%;
      }
      &--50 {
        width: 50%;
      }
      &--60 {
        width: 60%;
      }
      &--70 {
        width: 70%;
      }
      &--80 {
        width: 80%;
      }
      &--90 {
        width: 90%;
      }
      &--100 {
        width: 100%;
      }
    }
  }
  &__copy-mob-container {
    @include breakpoint($medium-down) {
      &--20 {
        width: 20%;
      }
      &--30 {
        width: 30%;
      }
      &--40 {
        width: 40%;
      }
      &--50 {
        width: 50%;
      }
      &--60 {
        width: 60%;
      }
      &--70 {
        width: 70%;
      }
      &--80 {
        width: 80%;
      }
      &--90 {
        width: 90%;
      }
      &--100 {
        width: 100%;
      }
    }
  }
  &__copy {
    clear: both;
    font-size: 18px;
    line-height: normal;
    padding-bottom: 4px;
    letter-spacing: normal;
    font-weight: inherit;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      font-size: 24px;
    }
    p {
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      font-family: inherit;
    }
    .hero-banner {
      &__title {
        font-size: 32px;
        line-height: 1;
        text-align: center;
        @include breakpoint($medium-up) {
          font-size: 60px;
        }
      }
      &__content {
        font-size: 18px;
        line-height: 1;
        text-align: center;
        @include breakpoint($medium-up) {
          font-size: 24px;
        }
      }
    }
  }
  &__heading {
    font-size: 32px;
    line-height: 1;
    padding-bottom: 10px;
  }
  &__content {
    font-size: 17px;
    line-height: 1;
  }
  &__button {
    clear: both;
    // Overwritten the style for live chat box
    .LPMcontainer {
      display: inline-block !important;
    }
    &--btn-primary {
      @include cr18-btn-primary;
      @include primary-hover;
    }
    &--btn-primary-option {
      @include cr18-btn-primary-option;
      @include primary-hover;
    }
    &--btn-secondary {
      @include cr18-btn-secondary;
    }
  }
  .basic-grid--full-width &__inner,
  .basic-grid--max-width-1800 &__inner {
    max-width: unset;
  }
  &__inner {
    max-width: 1070px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    min-height: 180px;
    @include breakpoint($medium-up) {
      min-height: 240px;
    }
    &.clickable-processed {
      cursor: pointer;
    }
  }
  &--border {
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: $color-black;
  }
  &--scale-height-to-text {
    min-height: 0;
  }
  &__lazyload-image {
    vertical-align: middle;
    width: 100%;
    @include breakpoint($medium-up) {
      height: 100%;
      min-height: 280px;
    }
  }
  &__text {
    text-align: center;
    width: 100%;
    word-wrap: break-word;
    padding: 18px 10px;
    clear: both;
    @include breakpoint($medium-up) {
      padding: 20px;
    }
    &.cta-tout__text-mob--align-left,
    &.cta-tout__text-mob--align-center,
    &.cta-tout__text-mob--align-right {
      .cta-tout__title,
      .cta-tout__copy-container,
      .cta-tout__button {
        @include breakpoint($medium-up) {
          text-align: unset;
          float: none;
        }
      }
    }
    &.cta-tout__text--align-left,
    &.cta-tout__text--align-center,
    &.cta-tout__text--align-right {
      .cta-tout__title,
      .cta-tout__copy-container,
      .cta-tout__button {
        text-align: unset;
        float: none;
      }
    }
    &.cta-tout__text-mob--align-left {
      .cta-tout__title,
      .cta-tout__copy-container,
      .cta-tout__button,
      .cta-tout__inline-eyebrow {
        text-align: left;
        float: left;
      }
    }
    &.cta-tout__text-mob--align-right {
      .cta-tout__title,
      .cta-tout__copy-container,
      .cta-tout__button,
      .cta-tout__inline-eyebrow {
        text-align: right;
        float: right;
      }
    }
    &.cta-tout__text-mob--align-center {
      .cta-tout__title,
      .cta-tout__copy-container,
      .cta-tout__button,
      .cta-tout__inline-eyebrow {
        text-align: center;
        float: none;
      }
    }
    &.cta-tout__text--align-left {
      .cta-tout__title,
      .cta-tout__copy-container,
      .cta-tout__button,
      .cta-tout__inline-eyebrow {
        @include breakpoint($medium-up) {
          text-align: left;
          float: left;
        }
      }
    }
    &.cta-tout__text--align-right {
      .cta-tout__title,
      .cta-tout__copy-container,
      .cta-tout__button,
      .cta-tout__inline-eyebrow {
        @include breakpoint($medium-up) {
          text-align: right;
          float: right;
        }
      }
    }
    &.cta-tout__text--align-center {
      .cta-tout__title,
      .cta-tout__copy-container,
      .cta-tout__button,
      .cta-tout__inline-eyebrow {
        @include breakpoint($medium-up) {
          text-align: center;
          float: none;
        }
      }
    }
    .product-grid__item & {
      @include breakpoint($medium-up) {
        padding: 5%;
      }
    }
    &--under-mobile-image,
    &--under-pc-image {
      position: relative;
      text-align: center;
      margin-top: 0;
      &.cta-tout__text--over-mobile-image {
        margin-top: 0;
        @include breakpoint($medium-up) {
          margin-top: 12px;
        }
      }
    }
    &--over-mobile-image {
      @include breakpoint($medium-down) {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        &.cta-tout__text-mob--position-top {
          transform: translateY(0%);
          top: 0;
          left: auto;
        }
        &.cta-tout__text-mob--position-center {
          transform: translateY(-50%);
          top: 50%;
          left: auto;
        }
        &.cta-tout__text-mob--position-bottom {
          transform: translateY(-100%);
          top: 100%;
          left: auto;
        }
      }
    }
    &--over-pc-image {
      @include breakpoint($medium-up) {
        position: absolute;
        &.cta-tout__text--position-top {
          transform: translateY(0%);
          top: 0;
          left: auto;
        }
        &.cta-tout__text--position-center {
          transform: translateY(-50%);
          top: 50%;
          left: auto;
        }
        &.cta-tout__text--position-bottom {
          transform: translateY(-100%);
          top: 100%;
          left: auto;
        }
      }
      &.cta-tout__text--position-center {
        .product-grid__item & {
          @include breakpoint($medium-up) {
            transform: translateY(-50%);
            top: 50%;
            left: auto;
          }
        }
      }
    }
    &--scale-height-to-text {
      position: relative;
    }
  }
  &__mobile-hide {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .cta-tout__product-cta-inventory-message {
    font-size: 16px;
    display: none;
  }
  [data-contains-unshoppable="true"]:not(.button--disabled) + .cta-tout__product-cta-inventory-message {
    display: block;
  }
}

.chat-hidden-mobile {
  display: none;
  @include breakpoint($portrait-up) {
    display: inline-block;
  }
}

.chat-hidden-pc {
  display: inline-block;
  @include breakpoint($portrait-up) {
    display: none;
  }
}

.cta-tout__copy a:not(.cta-tout__button--btn-primary) {
  @include secondary-hover;
  text-decoration: underline;
}
